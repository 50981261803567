import { Link } from '@remix-run/react'
import { SITE_NAME2 } from '#app/env.remix/config'
import { MenuLanguage } from './menu-language'

export type HeaderGuestProps = {
	i18n: {
		menu_activity_birthday: string
		menu_activity_featured: string
		menu_activity_photos: string
		menu_activity_members_online: string
		menu_activity_new_members: string
	}
	locale?: string
}

export function HeaderGuest(props: HeaderGuestProps) {
	return (
		<header className="sticky top-0 z-50 flex h-auto justify-between bg-gradient-to-b from-slate-900 via-slate-800 to-slate-700 px-2 pb-2.5 pt-3 sm:px-10">
			<Link
				className="flex items-center text-lg font-bold tracking-tighter text-slate-200 hover:text-white"
				to={`/${props.locale}`}
			>
				{SITE_NAME2}
			</Link>
			<div className="flex">
				<MenuLanguage />
			</div>
		</header>
	)
}
